import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Lagoon Linger: Dive into Delights
			</title>
			<meta name={"description"} content={"Dive into tales and tipples at Lagoon Linger."} />
			<meta property={"og:title"} content={"Home | Lagoon Linger: Dive into Delights"} />
			<meta property={"og:description"} content={"Dive into tales and tipples at Lagoon Linger."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_4.jpg?v=2023-10-23T14:23:43.447Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
		</Helmet>
		<Components.ToluqyeHeader />
		<Section
			padding="140px 0 140px 0"
			background="#FFFFFF url(https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_1.jpg?v=2023-10-23T14:27:18.376Z) 100% 0% /cover no-repeat scroll padding-box"
			min-height="100vh"
			lg-background="#fff"
			md-padding="96px 0 70px 0"
			sm-padding="72px 0 70px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Lagoon Linger
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					Lagoon Linger isn't just a bar – it's an immersion. Drift into a world where cocktails tell tales and the ambiance sings of serene lagoons. Here, every sip is an escape.
				</Text>
				<Box display="flex" sm-flex-direction="column" sm-text-align="center">
					<Link
						href="/contact-us"
						padding="12px 24px 12px 24px"
						color="--darkL1"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-darkL1"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						hover-transition="border 0.2s ease-in-out 0s"
						hover-border-color="--color-orange"
						transition="border 0.2s ease-in-out 0s"
					>
						Check the Address
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			/>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
							Discover the Lagoon
						</Text>
						<Text as="p" font="--lead" margin="0" color="--greyD2">
							Step into a unique setting reminiscent of a mystic lagoon. The water’s edge bar is where the magic happens. Tables nestled amidst shimmering blue floors mimic the feeling of wading through shallow waters, while overhead, soft lighting emulates the dance of moonlight on the lagoon’s surface.
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Cocktails from Depths Unknown
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Our signature drinks aren’t just mixed – they emerge. Order a “Deep Dive” and watch as your cocktail rises from the depths of our transparent bar counters, as if lifted by Neptune himself.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Message in a Bottle
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Want to order a drink or get the bill? Simply drop your request in a miniature bottle and set it afloat on the waterways that wind between tables. Our bartenders will catch your message and attend to your wishes.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									The Hidden Cove
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									An intimate corner where tales of old and new are shared. Here, customers can exchange stories, written on parchment, for a special one-time discount. Leave a tale, take a tale!
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Events & Reservations
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Lagoon Linger isn’t just a cocktail bar – it’s an experience. We host weekly storytelling sessions, moonlit musical nights, and mixology classes. Dive into our events calendar for more details or to reserve a spot by the lagoon.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			text-align="center"
			padding="80px 0"
			sm-padding="40px 0"
			align-items="center"
			justify-content="center"
			display="flex"
		>
			<Text as="h1" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Join Us
			</Text>
			<Text
				as="p"
				font="--lead"
				margin="20px 0 0 0"
				width="80%"
				text-align="center"
				align-self="center"
			>
				Every evening at Lagoon Linger promises mystique, memories, and mesmerising drinks. Immerse yourself in a cocktail experience like no other. Dive in, sip slowly, and linger longer with us.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box
					padding="10px"
					width="320px"
					height="504px"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image
						src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/pool_2.jpg?v=2023-10-23T14:27:18.542Z"
						width="auto"
						height="504px"
						srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/pool_2.jpg?v=2023-10-23T14%3A27%3A18.542Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/pool_2.jpg?v=2023-10-23T14%3A27%3A18.542Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/pool_2.jpg?v=2023-10-23T14%3A27%3A18.542Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/pool_2.jpg?v=2023-10-23T14%3A27%3A18.542Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/pool_2.jpg?v=2023-10-23T14%3A27%3A18.542Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/pool_2.jpg?v=2023-10-23T14%3A27%3A18.542Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/pool_2.jpg?v=2023-10-23T14%3A27%3A18.542Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					padding="10px"
					width="320px"
					height="504px"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_2.jpg?v=2023-10-23T14:27:18.367Z" height="504px" srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_2.jpg?v=2023-10-23T14%3A27%3A18.367Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_2.jpg?v=2023-10-23T14%3A27%3A18.367Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_2.jpg?v=2023-10-23T14%3A27%3A18.367Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_2.jpg?v=2023-10-23T14%3A27%3A18.367Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_2.jpg?v=2023-10-23T14%3A27%3A18.367Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_2.jpg?v=2023-10-23T14%3A27%3A18.367Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_2.jpg?v=2023-10-23T14%3A27%3A18.367Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
				</Box>
				<Box
					padding="10px"
					width="320px"
					height="504px"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14:23:43.465Z" height="504px" srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
				</Box>
			</Box>
		</Section>
		<Components.ToluqyeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});